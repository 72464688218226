import React from 'react';
import { Card } from '@tmap/mmm-style-guide/src/Card';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { ArrowForward } from '@tmap/mmm-style-guide/src/Icon';
import { styled } from '@tmap/mmm-style-guide/src/styled';

import ResponsivePicture from '../responsivePicture';

const CommunityCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.green.veryLight : theme.palette.green.dark,
  padding: theme.spacing(4, 9),
  borderRadius: '12px',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(6),
  },
}));

const ShortcutImage = styled(ResponsivePicture)(({ theme }) => ({
  objectFit: 'cover',
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    top: '-125px',
    right: '-35px',
  },
  [theme.breakpoints.between('xs', 'md')]: {
    position: 'relative',
    top: '-300px',
    objectFit: 'scale-down',
    maxWidth: '100%',
  },
}));

function SingleCommunityShortcut(props) {
  const {
    myCommunities, mainImage, leadCopy, subLeadCopy,
  } = props;
  return (
    <CommunityCard elevation={0}>
      <Grid container spacing={4} justifyContent='center' height='100%'>
        <Grid item xs={12} sm={10} md={6.5} lg={6.5}>
          <Grid container spacing={4}>
            <Grid item mt={2}>
              <Typography variant='body2' fontSize='12px!important' color='primary' sx={{ opacity: '50%' }}>{leadCopy}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='h4' fontSize='28px!important'>{subLeadCopy}</Typography>
            </Grid>
            <Grid
              item
              mb={{
                xs: -4, sm: -2, md: 2, lg: 2,
              }}
              width='100%'
            >
              <Grid container sx={{ margin: { xs: '0 auto', md: 0 } }} gap={2}>
                <Button sx={{ width: { xs: '100%', md: 'initial' } }} href={`/moving-living/${myCommunities[0].slug.current}/post`} size='large' variant='contained' color='primary' endIcon={<ArrowForward />}>
                  Post about {myCommunities[0].locationText}
                </Button>
                <Button sx={{ width: { xs: '100%', md: 'initial' } }} href={`/moving-living/${myCommunities[0].slug.current}`} size='large' variant='outlined' color='primary'>
                  Go to My Community
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5.5}
          lg={5.5}
          sx={{
            position: 'relative', height: { xs: '200px', sm: '200px', md: 'initial' }, bottom: { xs: '-50px', sm: '-35px', md: 'initial' }, overflowY: { xs: 'hidden', sm: 'hidden', md: 'initial' },
          }}
          display={{
            xs: 'grid', sm: 'grid', md: 'flex', lg: 'flex',
          }}
          justifyContent='center'
        >
          <ShortcutImage
            image={mainImage}
            height={525}
          />
        </Grid>
      </Grid>
    </CommunityCard>
  );
}

function MultiCommunityShortcut(props) {
  const {
    myCommunities, mainImage, leadCopy, subLeadCopy,
  } = props;
  return (
    <CommunityCard elevation={0}>
      <Grid container spacing={4} justifyContent='center' height='100%'>
        <Grid item xs={12} sm={10} md={6.5} lg={6.5}>
          <Grid container spacing={4}>
            <Grid item mt={2}>
              <Typography variant='body2' fontSize='12px!important' color='primary' sx={{ opacity: '50%' }}>{leadCopy}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='h4' fontSize='28px!important'>{subLeadCopy}</Typography>
            </Grid>
            <Grid
              item
              mb={{
                xs: -4, sm: -2, md: 0, lg: 0,
              }}
            >
              <Grid container flexDirection='column' sx={{ margin: { xs: '0 auto', md: 0 } }}>
                {myCommunities.slice(0, 3).map((community) => (
                  <Button href={`/moving-living/${community.slug.current}/post`} size='large' variant='text' color='primary' endIcon={<ArrowForward />}>
                    Post about {community.locationText}
                  </Button>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5.5}
          lg={5.5}
          sx={{
            position: 'relative', height: { xs: '200px', sm: '200px', md: 'initial' }, bottom: { xs: '-50px', sm: '-35px', md: 'initial' }, overflowY: { xs: 'hidden', sm: 'hidden', md: 'initial' },
          }}
          display={{
            xs: 'grid', sm: 'grid', md: 'flex', lg: 'flex',
          }}
          justifyContent='center'
        >
          <ShortcutImage
            image={mainImage}
            height={525}
          />
        </Grid>
      </Grid>
    </CommunityCard>
  );
}

export { SingleCommunityShortcut, MultiCommunityShortcut };
