import React, { useRef } from 'react';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

import ContentCard from '../contentCard';

function ArticlesBlock(props) {
  const { articles } = props;
  const swiperRef = useRef(null);
  return (
    <>
      <Grid container spacing={3} sx={{ display: { xs: 'none', lg: 'flex' } }}>
        {articles.map((article) => (
          <Grid item xs key={article._id}>
            <ContentCard article={article} />
          </Grid>
        ))}
      </Grid>
      <Grid container sx={{ display: { xs: 'flex', lg: 'none' } }}>
        <Swiper ref={swiperRef} slidesPerView='auto' spaceBetween={16} modules={[Navigation]}>
          {articles.map((article) => (
            <SwiperSlide key={article._id} style={{ maxWidth: 'max(33.33vw, 330px)' }}>
              <ContentCard article={article} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </>
  );
}
export default ArticlesBlock;
