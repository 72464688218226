import React, { useRef } from 'react';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Box } from '@tmap/mmm-style-guide/src/Box';
import { ArrowForward } from '@tmap/mmm-style-guide/src/Icon';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { ThemeProvider } from '@tmap/mmm-style-guide/src/Theming';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Card } from '@tmap/mmm-style-guide/src/Card';
import { amenityTags, communityTypeTags } from '@tmap/mmm-core/sanity/tags';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import LinkCard from '../linkCard';
import ResponsivePicture from '../responsivePicture';
import { DARK_THEME } from '../../lib/themes';
import useImpressions, { ImpressionTracker } from '../../hooks/useImpressions';

const amenityTypes = amenityTags.map((x) => x.value);
const communityTypes = communityTypeTags.map((x) => x.value);

const AmenityLinkCard = styled(LinkCard)(({ theme }) => ({
  height: '340px',
  borderRadius: '8px',
  padding: theme.spacing(2.75, 2.25),
  background: theme.palette.primary.veryLight,
}));

const EndCard = styled(Card)(({ theme }) => ({
  height: '340px',
  borderRadius: '8px',
  padding: theme.spacing(5, 3, 4, 3),
  background: theme.palette.primary.main,
}));

function AmenityCard(props) {
  const {
    card,
    placementId,
    shouldLazyLoad,
    lazyProps,
  } = props;
  const altText = card.image.alt || card.title;

  const tagValues = card.tags.map((tag) => tag.value);

  const communityTags = tagValues.filter(
    (tag) => communityTypes.includes(tag) || amenityTypes.includes(tag),
  );

  const filters = [];
  if (communityTags?.length > 0) filters.push(`tags=${communityTags.join(',')}`);
  const url = `/moving-living?${filters.join('&')}`;

  const { trackView, trackClick } = useImpressions(`amenity:${communityTags.join('+')}`, placementId);

  return (
    <ImpressionTracker trackView={trackView} trackClick={trackClick}>
      <AmenityLinkCard href={url} alt={altText}>
        <LinkCard.ImageWrapper>
          <LinkCard.Image borderRadius='8px' shouldLazyLoad={shouldLazyLoad} lazyProps={lazyProps}>
            <ResponsivePicture
              image={card.image}
              width={293}
              height={123}
              alt={altText}
              style={{ borderRadius: 'inherit' }}
            />
          </LinkCard.Image>
        </LinkCard.ImageWrapper>
        <LinkCard.Footer>
          <Box mt={6.25} px={2}>
            <ResponsivePicture
              image={card.icon}
              width={34}
              height={34}
              fit='clip'
              style={{ objectFit: 'contain' }}
              ignoreImageParams
            />
            <Typography variant='h6' fontSize='20px' fontWeight={600} mt={2} sx={{ maxWidth: '70%' }}>
              {card.title}
            </Typography>
          </Box>
        </LinkCard.Footer>
      </AmenityLinkCard>
    </ImpressionTracker>
  );
}

function AmenityBlock(props) {
  const { amenities = [] } = props;
  const swiperRef = useRef(null);
  return (
    <Grid container>
      <Swiper ref={swiperRef} slidesPerView='auto' spaceBetween={16}>
        {amenities.map((card, i) => (
          <SwiperSlide key={card._key} style={{ maxWidth: '328px' }}>
            <AmenityCard
              card={card}
              placementId={`homepageAmenities-${i}`}
              swiperRef={swiperRef}
              shouldLazyLoad={i > 3}
              lazyProps={{ offset: 400, height: 328 }}
            />
          </SwiperSlide>
        ))}
        <SwiperSlide style={{ maxWidth: '328px' }}>
          <EndCard>
            <ThemeProvider theme={DARK_THEME}>
              <Typography color='textPrimary' fontSize='24px' fontWeight={800} mb={2}>
                Places with Recruitment Programs
              </Typography>
              <Typography variant='body2' color='textSecondary' mb={7}>
                Many communities across the country are welcoming select new residents
                with incentives, and programming to help you connect with your new neighbors.
              </Typography>
              <Button
                href='/moving-living?relocation=true'
                size='large'
                variant='contained'
                color='secondary'
                endIcon={<ArrowForward />}
                sx={{ '& .MuiButtonBase-root': { background: (theme) => theme.palette.secondary.light } }}
              >
                Explore More
              </Button>
            </ThemeProvider>
          </EndCard>
        </SwiperSlide>
      </Swiper>
    </Grid>
  );
}
export default AmenityBlock;
