import locationLabelText from '@tmap/mmm-core/locationLabelText';

function getCommonProps(community) {
  return {
    communityUrl: `/moving-living/${encodeURIComponent(community.slug.current)}`,
    altText: `Life in ${locationLabelText(community.locationText, community.regionText)}`,
    primaryImage: community?.galleryImages?.[0],
  };
}

export default getCommonProps;
