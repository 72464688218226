import React, { useRef } from 'react';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@tmap/mmm-style-guide/src/Icon';
import { IconButton } from '@tmap/mmm-style-guide/src/IconButton';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import ConditionalLazyLoad from '../conditional-lazy-load';


import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

import imageUrlBuilder from '@sanity/image-url';
import client from '../../client';

const builder = imageUrlBuilder(client);

const mainImgStyles = ({ theme }) => ({
  borderRadius: '8px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  }
})
const MainPicture = styled('picture')(mainImgStyles);
const MainImg = styled('img')(mainImgStyles);

const profileImgStyles = (({ theme }) => ({
  borderRadius: '48px'
}))
const ProfilePicture = styled('picture')(profileImgStyles);
const ProfileImg = styled('img')(profileImgStyles);

const MainImgGrid = styled(Grid)(({ theme }) => ({
  paddingRight: '5%',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2)
  }
}))

const TextGrid = styled(Grid)(({ theme }) => ({
  padding: '5%',
  paddingTop: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2)
  }
}))

const ArrowGrid = styled(Grid)(({ theme }) => ({
  '&:first-of-type': {
    marginLeft: theme.spacing(-2),
  },
  '&:last-of-type': {
    marginRight: theme.spacing(-2),
  },
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    bottom: '-64px',
    '&:first-of-type': {
      marginLeft: theme.spacing(1),
      left: 0
    },
    '&:last-of-type': {
      marginRight: theme.spacing(1),
      right: 0
    },
  }
}))
const SwiperGrid = styled(Grid)(({ theme }) => ({
  width: `calc(100% - ${theme.spacing(12)})`,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  }
}))

const WrapperGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4)
  }
}))

const TestimonialsBlock = (props) => {
  const { testimonials } = props;
  const swiperRef = useRef();
  return (
    <WrapperGrid container alignItems='center' justifyContent='space-between' position='relative'>
      <ArrowGrid item>
        <IconButton variant='contained' size='large'
          onClick={() => swiperRef.current.swiper.slidePrev()}
          aria-label='Scroll Testimonials Back' >
          <KeyboardArrowLeft fontSize='large' />
        </IconButton>
      </ArrowGrid>
      <SwiperGrid item>
        <Swiper ref={swiperRef} slidesPerView={1} spaceBetween={32} modules={[Navigation]}>
          {testimonials.map((tm, i) => tm && (
            <SwiperSlide key={'home-testimonial-' + i}>
              <Grid container direction='row-reverse' justifyContent='center'>
                <MainImgGrid item xs={12} md>
                  <Grid container justifyContent='center'>
                    <Grid item>
                      <ConditionalLazyLoad shouldLazyLoad={true} offset={1000}>
                        <MainPicture>
                          <source srcSet={builder.image(tm.image).format('webp').width(320).height(400).url()} media='(min-width: 900px)' />
                          <source srcSet={builder.image(tm.image).format('webp').width(900).height(400).url()} />
                          <MainImg src={builder.image(tm.image).width(900).height(400).url()}
                            className={'mainImage'}
                            alt={tm.image.alt}
                          />
                        </MainPicture>
                      </ConditionalLazyLoad>
                    </Grid>
                  </Grid>
                </MainImgGrid>
                <TextGrid item xs={12} md>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      {tm.leadCopy && <Typography variant='h5' fontWeight={600}>{tm.leadCopy}</Typography>}
                    </Grid>
                    <Grid item xs={12}>
                      {tm.subLeadCopy && <Typography variant='body1'>{tm.subLeadCopy}</Typography>}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems='center' spacing={2}>
                        <Grid item>
                          <ConditionalLazyLoad shouldLazyLoad={true} offset={1000}>
                            <ProfilePicture>
                              <source srcSet={builder.image(tm.profileImage).format('webp').width(96).height(96).url()} width={48} height={48} />
                              <ProfileImg src={builder.image(tm.profileImage).width(96).height(96).url()} width={48} height={48}
                                alt={tm.profileImage.alt}
                              />
                            </ProfilePicture>
                          </ConditionalLazyLoad>
                        </Grid>
                        <Grid item>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography variant='body1' sx={{ fontWeight: '600' }}>{tm.name}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant='body2' color='textSecondary'>
                                {tm.from}<Typography component='span' color='secondary'>&nbsp;&nbsp;--{'>'}&nbsp;&nbsp;</Typography>{tm.to}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </TextGrid>
              </Grid>
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperGrid>
      <ArrowGrid item>
        <IconButton variant='contained' size='large'
          onClick={() => swiperRef.current.swiper.slideNext()}
          aria-label='Scroll Testimonials Forward' >
          <KeyboardArrowRight fontSize='large' />
        </IconButton>
      </ArrowGrid>
    </WrapperGrid>
  )
}

export default TestimonialsBlock