import React, { useMemo, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useTheme } from '@tmap/mmm-style-guide/src/Theming';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Box } from '@tmap/mmm-style-guide/src/Box';
import { Skeleton } from '@tmap/mmm-style-guide/src/Skeleton';

import useView from '@tmap/mmm-core/redux/views/useView';

import ColorBlocks from '../../layouts/colorBlocks';
import { getRecommendedContent } from '../../actions/contributions';
import RecommendedContentCard from './recommendedContentCard';

import 'swiper/css';
import 'swiper/css/navigation';

const BrowseAllButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(6),
  background: theme.palette.common.white,
  width: 'max-content',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  borderWidth: '2px 0px 2px 0px',
  borderStyle: 'solid',
  borderColor: theme.palette.grey[200],
}));

const HeaderText = styled(Typography)(() => ({
  fontSize: '28px',
  fontWeight: 800,
  lineHeight: '37.38px',
  letterSpacing: '0.25px',
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  lineHeight: '25.6px',
  marginTop: theme.spacing(1),
}));

const introSlideStyle = {
  maxWidth: '328px',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingRight: '16px',
};

const contentSlideStyle = { maxWidth: '328px' };

const CONTENT_BACKGROUND_URL = 'https://cdn.sanity.io/images/u721u3r1/production/f22687e3e2569618f7e092dc09257c5ef0fa6f48-2884x1628.png';

function RecommendedContentBlock() {
  const [recommendedContent, view] = useView('recommended-content', getRecommendedContent());

  const theme = useTheme();
  const CONTENT_COLORS = useMemo(() => ([
    theme.palette.purple.main,
    theme.palette.convert.main,
    theme.palette.green.dark,
    theme.palette.primary.light,
    theme.palette.secondary.main,
    theme.palette.pink.dark,
    theme.palette.aqua.main,
    theme.palette.blue.dark,
    theme.palette.warning.dark,
    theme.palette.info.dark,
  ]), [theme]);

  const swiperRef = useRef(null);

  return (
    <ContentContainer>
      <ColorBlocks.Block color='light' overflow background={`no-repeat center/cover url(${CONTENT_BACKGROUND_URL})`}>
        <Swiper ref={swiperRef} slidesPerView='auto' spaceBetween={16}>
          <SwiperSlide style={introSlideStyle}>
            <HeaderText color='textPrimary'>
              Find Your Place by Exploring Local Stories
            </HeaderText>
            <DescriptionText fontWeight={400} color='textPrimary'>
              Discover the heart of each community through insights and experiences shared by
              those who live there.
            </DescriptionText>
            <BrowseAllButton
              variant='outlined'
              color='inverted'
              size='large'
              href='/moving-living'
            >
              Browse all communities
            </BrowseAllButton>
          </SwiperSlide>
          {(!view.isReady || view.isLoading) && [1, 2, 3, 4].map((i) => (
            <SwiperSlide key={`placeholder-${i}`} style={contentSlideStyle}>
              <Skeleton variant='rectangular' width={328} height={558} />
            </SwiperSlide>
          ))}
          {recommendedContent?.map((post, i) => {
            const color = CONTENT_COLORS[i % CONTENT_COLORS.length];
            return (
              <SwiperSlide key={post._id} style={contentSlideStyle}>
                <RecommendedContentCard
                  key={post._id}
                  post={post}
                  color={color}
                  placementId={`homepage-ugc-${i}`}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </ColorBlocks.Block>
    </ContentContainer>
  );
}

export default RecommendedContentBlock;
