import React, { useRef } from 'react';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Card } from '@tmap/mmm-style-guide/src/Card';
import { ThemeProvider, useTheme } from '@tmap/mmm-style-guide/src/Theming';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { ArrowForward } from '@tmap/mmm-style-guide/src/Icon';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Box } from '@tmap/mmm-style-guide/src/Box';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

import GalleryCommunityCard from '../communityCard/gallery';
import { DARK_THEME } from '../../lib/themes';

const EndCard = styled(Card)(({ theme }) => ({
  height: '100%',
  borderRadius: '8px',
  padding: theme.spacing(6, 3, 7, 3),
  background: theme.palette.grey[900],
}));

function EndCardIcon() {
  const theme = useTheme();
  return (
    <Box display='flex' alignItems='center' width='50%' mb={3}>
      <svg fill='none' width='226' height='60' viewBox='0 0 226 60' xmlns='http://www.w3.org/2000/svg'>
        <path fill={theme.palette.pink.light} d='m7.02513 59.0142v-21.311l-3.77973 3.1257-2.519814-3.6371 6.299544-5.2283v-7.0468h4.19967v3.5802l12.5991-10.3997 23.0983 19.0946-2.5198 3.5802-3.7797-3.0688v21.311zm4.19967-4.5463h10.4993v-9.0927h4.1997v9.0927h10.4992v-20.2312l-12.5991-10.3998-12.5991 10.3998z' />
        <path fill={theme.palette.aqua.light} d='m70.5719 59.0142v-18.8236h-7.65l25.5-21.1764 25.5001 21.1764h-7.65v18.8236h-5.1v-4.7059h-25.5001v4.7059zm11.3475-28.2353h13.005l-6.5025-5.4118zm-6.2475 9.4117h25.5001v-4.2353l-.574-.4706h-24.3524l-.5737.4706zm0 9.4118h25.5001v-4.7059h-25.5001z' />
        <path fill={theme.palette.green.main} fillRule='evenodd' clipRule='evenodd' d='m135.127 47.9919v11.0223h18.59v-11.0223l2.045 3.1111 3.16-1.8666-14.5-22.2222-14.5 22.2222 3.16 1.8666zm7.436 7.4667h-3.718v-13.1556l5.577-8.5333 5.577 8.5333v13.1556h-3.718v-5.3333h-3.718z' />
        <path fill={theme.palette.green.main} d='m142.563 46.5697v-3.5555h3.718v3.5555z' />
        <path fill={theme.palette.convert.veryLight} d='m174.922 59.0141v-42.3808l36.491-16.300292v29.340592h2.807c0-1.7931.55-3.328 1.65-4.6049 1.099-1.2768 2.421-1.9153 3.964-1.9153 1.544 0 2.866.6385 3.965 1.9153 1.1 1.2769 1.65 2.8118 1.65 4.6049v29.3405zm5.614-6.5201h11.228v-22.8204h14.035v-19.88642l-25.263 11.32872zm16.842 0h8.421v-9.7802h5.614v9.7802h8.421v-16.3003h-22.456z' />
      </svg>
    </Box>
  );
}

function FeaturedCommunitiesBlock(props) {
  const { featuredCommunities = [] } = props;
  const swiperRef = useRef(null);
  return (
    <Grid container>
      <Swiper ref={swiperRef} slidesPerView={featuredCommunities.length < 2 ? 1 : 'auto'} spaceBetween={16} modules={[Navigation]}>
        {featuredCommunities.map((community, i) => (
          <SwiperSlide key={community._id} style={{ maxWidth: '328px' }}>
            <GalleryCommunityCard
              community={community}
              disableSwiper
              shouldLazyLoad
              lazyProps={{ offset: 400, height: 334 }}
              placementId={`homepageCommunities-${i}`}
              pageId='homepage'
            />
          </SwiperSlide>
        ))}
        <SwiperSlide style={{ maxWidth: '328px' }}>
          <EndCard>
            <EndCardIcon />
            <ThemeProvider theme={DARK_THEME}>
              <Typography color='textPrimary' fontSize='24px' fontWeight={800} mb={2}>
                Move to the place<br />
                that moves you.
              </Typography>
              <Typography variant='body2' color='textSecondary' mb={4}>
                Incredible communities are welcoming remote workers
                with incentives and networking programs.
              </Typography>
              <Button
                href='/moving-living'
                size='large'
                variant='outlined'
                color='inverted'
                fullWidth
                endIcon={<ArrowForward />}
              >
                See all communities
              </Button>
            </ThemeProvider>
          </EndCard>
        </SwiperSlide>
      </Swiper>
    </Grid>
  );
}

export default FeaturedCommunitiesBlock;
