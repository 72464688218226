import React, { useMemo } from 'react';

import { Box } from '@tmap/mmm-style-guide/src/Box';
import CircleIcon from '@tmap/mmm-style-guide/src/CircleIcon';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Hyperlink } from '@tmap/mmm-style-guide/src/Hyperlink';
import { LocationOn } from '@tmap/mmm-style-guide/src/Icon';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { styled } from '@tmap/mmm-style-guide/src/styled';

import { toKey, types } from '@tmap/mmm-core/entityKey';
import getPreviewText from '@tmap/mmm-core/slate/getPreviewText';

import ContributionMedia from '../communityContent/contributionMedia';
import { CardProfile } from '../communityContent/cardProfile';
import useImpressions, { ImpressionTracker } from '../../hooks/useImpressions';

const CommunityHeader = styled(Grid)(({ theme }) => ({
  flexWrap: 'nowrap',
  alignItems: 'center',
  marginLeft: theme.spacing(-0.5),
  marginBottom: theme.spacing(0.5),
  padding: theme.spacing(0.5, 0),
  overflowX: 'hidden',
  maxWidth: '100%',
}));

const CommunityLabel = styled(Typography)(() => ({
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

const BorderedMediaContainer = styled(Box, {
  shouldForwardProp: (p) => p !== 'color',
})(({ color }) => ({
  maxWidth: '100%',
  width: '328px',
  height: '373px',
  borderRadius: '16px',
  border: `4px solid ${color}`,
  overflow: 'hidden',
}));

const NoColorHyperlink = styled(Hyperlink)(() => ({
  color: 'inherit',
}));

const cardLinkStyle = {
  textDecoration: 'none',
  display: 'inline-block',
  width: '328px',
  maxWidth: '100%',
};

const NoOverflowGrid = styled(Grid)(() => ({
  overflowX: 'hidden',
}));

function RecommendedContentCard(props) {
  const { post, color, placementId } = props;
  const impressionMeta = useMemo(() => ({ pageId: 'homepage' }), []);
  const { trackView, trackClick } = useImpressions(
    toKey(types.CONTRIBUTION, post._id),
    placementId,
    impressionMeta,
  );
  const previewText = getPreviewText(post.content, { maxLength: 200, ellipse: true });
  return (
    <ImpressionTracker trackView={trackView} trackClick={trackClick}>
      <NoColorHyperlink href={`/moving-living/${post.community.slug}`}>
        <CommunityHeader container spacing={1}>
          <Grid item>
            <CircleIcon
              Icon={LocationOn}
              color={color}
              variant='contained'
              fontSize='small'
            />
          </Grid>
          <NoOverflowGrid item xs>
            <CommunityLabel
              fontWeight={500}
              lineHeight='24px'
              fontSize='16px'
              color='textPrimary'
            >
              {post.community.label}
            </CommunityLabel>
          </NoOverflowGrid>
        </CommunityHeader>
      </NoColorHyperlink>
      <NoColorHyperlink href={`/c/${post.slug}`} style={cardLinkStyle}>
        <BorderedMediaContainer color={color}>
          <ContributionMedia
            borderRadius='0'
            media={post.media[0]}
            width={328}
            height={373}
            fitWidth
            fitHeight
          />
        </BorderedMediaContainer>
        <Box mt={1}>
          <CardProfile
            author={post.author}
            contribution={post}
            hideAuthorLocation
          />
        </Box>
        <Typography variant='caption' color='textSecondary'>
          {previewText}
        </Typography>
      </NoColorHyperlink>
    </ImpressionTracker>
  );
}

export default RecommendedContentCard;
