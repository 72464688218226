import React from 'react';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { Box } from '@tmap/mmm-style-guide/src/Box';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { ArrowForward } from '@tmap/mmm-style-guide/src/Icon';

import { useRouter } from 'next/router';
import imageUrlBuilder from '@sanity/image-url';
import ConditionalLazyLoad from '../conditional-lazy-load';
import client from '../../client';

const builder = imageUrlBuilder(client);

const imgStyles = ({ theme }) => ({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  borderRadius: '12px',
  [theme.breakpoints.down('sm')]: {
    borderRadius: '8px',
  },
});
const StyledPicture = styled('picture')(imgStyles);
const StyledImg = styled('img')(imgStyles);

function ListCommunityBlock(props) {
  const {
    shouldLazyLoad = true,
    lazyProps = { offset: 1000 },
    mainImage,
    leadCopy,
    subLeadCopy,
    buttonText = 'List Your Community',
    buttonUrl = '/list-community',
  } = props;
  const router = useRouter();

  return (
    <Box px={{ xs: 0, md: 8 }}>
      <Grid container spacing={{ xs: 3, md: 8 }}>
        <Grid item xs={12} md={6} sx={{ maxHeight: '100%' }}>
          <ConditionalLazyLoad shouldLazyLoad={shouldLazyLoad} {...lazyProps}>
            <StyledPicture>
              <source srcSet={builder.image(mainImage).width(400).height(400).format('webp').url()} />
              <StyledImg src={builder.image(mainImage).width(400).height(400).format('webp').url()}
                alt={mainImage.alt}
              />
            </StyledPicture>
          </ConditionalLazyLoad>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} alignItems='space-between' height='100%'>
            <Grid item xs={12}>
              {leadCopy && <Typography variant='h4' fontWeight={600} color='inverted'>{leadCopy}</Typography>}
            </Grid>
            <Grid item xs={12}>
              {subLeadCopy && <Typography variant='body1' color='textSecondary' whiteSpace='pre-line'>{subLeadCopy}</Typography>}
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              {(buttonText && buttonUrl) && (
                <Button variant='contained' color='secondary' onClick={() => router.push(buttonUrl)} endIcon={<ArrowForward />}>
                  {buttonText}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ListCommunityBlock;
