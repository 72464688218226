import React from 'react';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Box } from '@tmap/mmm-style-guide/src/Box';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';

import LazyLoad from 'react-lazyload';
import imageUrlBuilder from '@sanity/image-url';
import client from '../../client';

const builder = imageUrlBuilder(client);

const imgStyles = () => ({
  height: '80px',
});
const HiwPicture = styled('picture')(imgStyles);
const HiwImg = styled('img')(imgStyles);

function HiwBlock(props) {
  const { hiwBlocks = [] } = props;
  return (
    <Grid container spacing={6} mb={8}>
      {hiwBlocks.map((block, i) => (
        <Grid item xs={12} md={6} key={block._key}>
          <Grid container spacing={3}>
            <Grid item>
              <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' width='80px'>
                <LazyLoad offset={600}>
                  <HiwPicture>
                    <source srcSet={builder.image(block.image).format('webp').height(160).url()} />
                    <HiwImg
                      src={builder.image(block.image).height(160).url()}
                      alt={(block.image || {}).alt}
                    />
                  </HiwPicture>
                </LazyLoad>
              </Box>
            </Grid>
            <Grid item xs>
              <Typography variant='subtitle' component='div' fontWeight={500} mb={1.5}>
                <Typography component='span' fontWeight={600}>0{i + 1}&ensp;</Typography>
                {block.leadCopy}
              </Typography>
              <Typography variant='body2' color='textSecondary'>{block.subLeadCopy}</Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default HiwBlock;
