import React, { useRef } from 'react';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

import IncentiveCard from '../incentiveCard';

const FeaturedIncentivesBlock = (props) => {
  const { featuredIncentives } = props;
  const swiperRef = useRef(null);
  return (
    <>
      <Grid container spacing={3} sx={{ display: { xs: 'none', lg: 'flex' } }}>
        {featuredIncentives.slice(0, 2).map((incentive, i) => (
          <Grid item xs={6} key={`incentive-grid-${incentive._id}`}>
            <IncentiveCard incentive={incentive} shouldLazyLoad placementId={`homepageOffers-${i}`} pageId='homepage' />
          </Grid>
        ))}
        {featuredIncentives.slice(2).map((incentive, i) => (
          <Grid item xs={4} key={`incentive-grid-${incentive._id}`}>
            <IncentiveCard incentive={incentive} shouldLazyLoad lazyProps={{ offset: 200, height: 206.5 }} placementId={`homepageOffers-${2 + i}`} pageId='homepage' />
          </Grid>
        ))}
      </Grid>
      <Grid container sx={{ display: { xs: 'flex', lg: 'none' } }}>
        <Swiper ref={swiperRef} slidesPerView='auto' spaceBetween={16} modules={[Navigation]}>
          {featuredIncentives.map((incentive, i) => (
            <SwiperSlide key={`incentive-slide-${incentive._id}`} style={{ maxWidth: 'max(33.33vw, 330px)' }}>
              <IncentiveCard incentive={incentive} shouldLazyLoad lazyProps={{ offset: (i < 3 ? 200 : 0), height: 206.5 }} placementId={`homepageOffers-${i}`} pageId='homepage' />
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </>
  )
}

export default FeaturedIncentivesBlock;