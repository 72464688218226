import React, { useMemo, useRef } from 'react';
import { toKey, types } from '@tmap/mmm-core/entityKey';
import useImpressions, { ImpressionTracker } from '../../../hooks/useImpressions';
import findImpressionFeature from '../../../lib/findImpressionFeature';

function CommunityImpressionWrapper(props) {
  const {
    community,
    placementId,
    pageId,
    favoriteTarget = { current: null },
    exploreTarget = { current: null },
    children,
  } = props;
  const impressionMeta = useMemo(() => ({ pageId }), [pageId]);
  const { trackView, trackClick } = useImpressions(
    toKey(types.COMMUNITY, community._id),
    placementId,
    impressionMeta,
  );

  const baseElement = useRef();

  const handleImpressionClick = (event) => {
    const elementsToTrack = new Map([
      [favoriteTarget.current, 'favorite'],
      [exploreTarget.current, 'explore'],
      [baseElement.current, 'base'],
    ]);

    const featureName = findImpressionFeature(event, elementsToTrack);
    trackClick({ feature: featureName });
  };
  return (
    <ImpressionTracker
      trackView={trackView}
      onClickCapture={handleImpressionClick}
      ref={baseElement}
    >
      {children}
    </ImpressionTracker>
  );
}

export default CommunityImpressionWrapper;
