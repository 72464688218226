import React from 'react';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Chip } from '@tmap/mmm-style-guide/src/Chip';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

import 'swiper/css';
import 'swiper/css/navigation';

import imageUrlBuilder from '@sanity/image-url';
import client from '../client';
import sentenceCase from '../lib/sentenceCase';
import LinkCard from './linkCard';

const builder = imageUrlBuilder(client);

const imgStyles = () => ({
  width: '100%',
  objectFit: 'cover',
  borderRadius: 'inherit',
});
const StyledPicture = styled('picture')(imgStyles);
const StyledImg = styled('img')(imgStyles);

function ArticleCard(props) {
  const {
    article,
    variant = 'contained',
    aspect = 'medium',
    hideType = false,
  } = props;
  const typeUrls = {
    communityArticles: 'community-articles',
  };
  const url = `/${typeUrls[article._type] ?? article._type}/${article.slug.current}`;

  const imageProps = variant === 'floating' ? { borderRadius: '8px' } : {};

  const previewImage = article.heroVideo?.previewImage || article.heroImage;
  const altText = previewImage?.alt || article.title;

  const aspectRatios = {
    medium: { width: 391, height: 217 },
    large: { width: 590, height: 230 },
  };
  const imageDimensions = aspectRatios[aspect];

  return (
    <LinkCard href={url} alt={altText} variant={variant} sx={{ height: '100%' }}>
      <LinkCard.ImageWrapper>
        <LinkCard.Image shouldLazyLoad lazyProps={{ offset: 1000 }} {...imageProps}>
          <StyledPicture>
            <source srcSet={builder.image(previewImage).format('webp').height(imageDimensions.height * 2).url()} height={imageDimensions.height} />
            <StyledImg
              src={builder.image(previewImage).height(imageDimensions.height * 2).url()}
              height={imageDimensions.height}
              width={imageDimensions.width}
              alt={altText}
            />
          </StyledPicture>
        </LinkCard.Image>
      </LinkCard.ImageWrapper>
      <LinkCard.Footer>
        <Grid container spacing={1} sx={{ padding: variant === 'floating' ? 1 : 2, paddingTop: 0.5 }} alignItems='center'>
          <Grid item xs><Typography variant='body2' color='textSecondary'>{format(parseISO(article._createdAt), 'MMM d, yyyy')}</Typography></Grid>
          {!hideType && <Grid item><Chip variant='pill' size='small' label={sentenceCase(article._type)} /></Grid>}
          <Grid item xs={12}><Typography variant='h6' fontWeight={600} color='dark'>{article.title}</Typography></Grid>
        </Grid>
      </LinkCard.Footer>
    </LinkCard>
  );
}

export default ArticleCard;
